import { InhouseGameType } from 'config/types/game'
import { Icons } from 'svgs'

const InhouseGameIcons: React.FC<
  {
    type: InhouseGameType
  } & any
> = ({ type, ...props }) => {
  switch (type) {
    case InhouseGameType.Dice:
      return <Icons.DiceLoadingIcon {...props} />
    case InhouseGameType.Plinko:
      return <Icons.PlinkoLoadingIcon {...props} />
    case InhouseGameType.Limbo:
      return <Icons.LimboLoadingIcon {...props} />
    case InhouseGameType.Wheel:
      return <Icons.WheelLoadingIcon {...props} />
    case InhouseGameType.Mine:
      return <Icons.MineLoadingIcon {...props} />
    default:
      return null
  }
}

export default InhouseGameIcons
