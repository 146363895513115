import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Image from 'components/Image'
import { HEIGHT_ROW, TableCellHistory, TableRowHistory, TableRowValueHistory } from 'components/Table/TableHistory'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import { DISPLAY_AMOUNT } from 'config/constants'
import { Token } from 'config/types'
import { BetDetails } from 'config/types/profile'
import useGameTag from 'hooks/useGameTag'
import Column from 'layout/Components/Column'
import { RowMiddle } from 'layout/Components/Row'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import theme from 'theme'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'
import TokenAmountFiatDisplay from 'views/Fiat/TokenAmountFiatDisplay'
import InhouseGameIcons from 'views/InhouseGame/components/InhouseGameIcons'
import { DisplayMultiplier } from 'views/Profile/Transactions/components/styled'
import UserDisplay from 'views/Profile/components/UserDisplay'

const CurrencyAmount: React.FC<{
  token: Token
  value: string
  color?: string
}> = ({ token, value, color = theme.colors.text }) => {
  const {
    userSettings: { isDisplayInFiat },
  } = useUserSettings()

  return (
    <RowMiddle>
      <Box minWidth="24px">
        <TokenLogo token={token} size={20} networkImageProps={{ width: 12, height: 12 }} />
      </Box>
      <Column ml="8px">
        <TableRowValueHistory color={color} fontWeight={500} lineHeight="14px">
          {isDisplayInFiat ? (
            <TokenAmountFiatDisplay
              tokenAmount={{
                token,
                amount: BigNumber(value),
              }}
            />
          ) : (
            getFullDisplayBalance(BigNumber(value), 0, DISPLAY_AMOUNT)
          )}
        </TableRowValueHistory>
      </Column>
    </RowMiddle>
  )
}

const LiveBetRow: React.FC<
  BetDetails & {
    className?: string

    onModalDetails: () => void
  }
> = ({ bet, player, payout, multiplier, game, className, onModalDetails }) => {
  const gameTag = useGameTag(game.tag)
  const formatMultiplier = new BigNumber(multiplier)
  return (
    <TableRowHistory
      className={className}
      onClick={onModalDetails}
      style={{
        cursor: 'pointer',
      }}
    >
      <TableCellHistory height={HEIGHT_ROW}>
        <RowMiddle>
          <Box minWidth={20} height={20} width={20}>
            {game?.inhouseGameType ? (
              <InhouseGameIcons type={game?.inhouseGameType} fill={colors.textSubtle} />
            ) : (
              <StyledImage src={gameTag?.img} width={20} height={20} alt="game-tag" />
            )}
          </Box>
          <TableRowValueHistory ellipsis={1} ml="8px">
            {game.name}
          </TableRowValueHistory>
        </RowMiddle>
      </TableCellHistory>

      <TableCellHistory height={HEIGHT_ROW}>
        <StyledUserDisplay user={player} />
      </TableCellHistory>

      <TableCellHistory height={HEIGHT_ROW} display={['none', '', 'table-cell']}>
        <CurrencyAmount token={bet.token} value={bet.amount} />
      </TableCellHistory>

      <TableCellHistory height={HEIGHT_ROW} display={['none', '', '', '', 'table-cell']}>
        <TableRowValueHistory>
          <DisplayMultiplier amount={formatMultiplier} color={formatMultiplier.gt(0) ? 'success' : 'textAlt1'} />
        </TableRowValueHistory>
      </TableCellHistory>

      <TableCellHistory height={HEIGHT_ROW}>
        <CurrencyAmount
          token={payout.token}
          value={payout.amount}
          color={new BigNumber(payout.amount).gt(0) ? 'success' : 'textAlt1'}
        />
      </TableCellHistory>
    </TableRowHistory>
  )
}

const StyledUserDisplay = styled(UserDisplay)`
  ${Text} {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    ${Text} {
      display: block;
    }
  }
`

const StyledImage = styled(Image)`
  img {
    filter: grayscale(1) invert(1);
  }
`
export default LiveBetRow
