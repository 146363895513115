import Box from 'components/Box/Box'
import EmptyContent from 'components/EmptyContent'
import Table from 'components/Table'
import { TableBody } from 'components/Table/Table'

import { HEIGHT_ROW, TableCellHistory, TableHeaderValueHistory, TableRowHistory } from 'components/Table/TableHistory'
import LoadingTable from 'components/Table/TableHistory/LoadingTable'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Paging as APIPaging, BaseResponse, HunnyRequest, Paging } from 'services/types'

import { BetDetails } from 'config/types/profile'
import { usePublicSocket } from 'hooks/usePublicSocket'
import { useRequest } from 'hooks/useRequest'
import { parseBetDetailsItemMapper } from 'services/mapper/Game'
import styled from 'styled-components'
import { range } from 'utils'
import useOpenBetDetails from 'hooks/useOpenBetDetails'
import LiveBetRow from './LiveBetRow'

const MAX_LIMIT_LIST_LIVE_BET = 30

const LiveBetTable: React.FC<{
  fetchFn: () => HunnyRequest<BaseResponse<Paging<BetDetails>>>
  validateBet: (bet: BetDetails) => boolean
  displayRowCount: number
}> = ({ fetchFn, validateBet, displayRowCount }) => {
  const { execute } = useRequest()
  const [results, setResult] = useState<
    {
      active: boolean
      item: BetDetails
    }[]
  >([])

  const [isLoading, setIsLoading] = useState(false)
  const socket = usePublicSocket()
  const { presentBetModal } = useOpenBetDetails()
  const refBody = useRef(null)

  const fetchHistory = useCallback(async (): Promise<APIPaging<BetDetails>> => {
    setIsLoading(true)
    const response = await execute(fetchFn())

    if (!response?.data) {
      setIsLoading(false)
      setResult([])
      return
    }

    const rowData: BetDetails[] = response.data?.items

    setResult(
      rowData.map((item, index) => ({
        active: index % 2 !== 0,
        item,
      })),
    )
    setIsLoading(false)
  }, [fetchFn])

  useEffect(() => {
    fetchHistory()
  }, [fetchHistory])

  useEffect(() => {
    if (!socket) {
      return
    }

    socket.on('social.live.bet', (newBet) => {
      if (newBet) {
        const parsedNewBet = parseBetDetailsItemMapper(newBet)

        if (!validateBet(parsedNewBet)) {
          return
        }

        if (parsedNewBet.betId) {
          refBody.current?.classList.remove('animation--start')
          refBody.current?.classList.add('animation--start')

          setResult((preState) => {
            return [
              {
                active: !preState?.[0]?.active,

                item: parsedNewBet,
              },
              ...preState,
            ].slice(0, MAX_LIMIT_LIST_LIVE_BET)
          })

          setTimeout(() => {
            refBody.current?.classList.remove('animation--start')
          }, 500)
        }
      }
    })

    return () => {
      if (socket) {
        socket.off('social.live.bet')
      }
    }
  }, [socket, validateBet])

  return (
    <Box
      position="relative"
      minHeight="200px"
      mb={`-${displayRowCount !== MAX_LIMIT_LIST_LIVE_BET ? HEIGHT_ROW : 0}px`}
    >
      <StyledTable>
        <thead>
          <tr>
            <TableHeaderValueHistory key={'Game'} width={['25%', '', '20%', '', '25%']} label={'Game'} />
            <TableHeaderValueHistory key={'Player'} width={['5%', '', '10%', '', '20%']} label={'Player'} />
            <TableHeaderValueHistory
              key={'Bet Amount'}
              width={['10%', '', '20%', '', '20%']}
              label={'Bet Amount'}
              display={['none', '', 'table-cell']}
            />
            <TableHeaderValueHistory
              key={'Multiplier'}
              width={['10%', '', '', '11%']}
              label={'Multiplier'}
              display={['none', '', '', '', 'table-cell']}
            />
            <TableHeaderValueHistory key={'Payout'} label={'Payout'} width={['33%', '', '20%', '', '20%']} />
          </tr>
        </thead>

        <TableBody ref={refBody}>
          {isLoading ? (
            <>
              <TableRowHistory>
                <TableCellHistory
                  style={{
                    position: 'relative',
                  }}
                  className="loader"
                  colSpan={100}
                  pl="0px !important"
                >
                  <LoadingTable loading top="0" />
                </TableCellHistory>
              </TableRowHistory>

              {range(0, displayRowCount).map((idx) => (
                <TableRowHistory key={`table-row-loader-${idx}`}>
                  <TableCellHistory className="loader" colSpan={100} pl="0px !important">
                    <Box width="100%" height="52px" />
                  </TableCellHistory>
                </TableRowHistory>
              ))}
            </>
          ) : (
            <>
              {results.slice(0, Math.min(displayRowCount + 1, MAX_LIMIT_LIST_LIVE_BET)).map((result) => {
                return (
                  <LiveBetRow
                    className={result.active ? 'active' : ''}
                    onModalDetails={() => {
                      presentBetModal(result.item.betId)
                    }}
                    {...result.item}
                  />
                )
              })}
            </>
          )}
        </TableBody>
      </StyledTable>

      {results?.length > 0
        ? displayRowCount < MAX_LIMIT_LIST_LIVE_BET && <StyledOverlay />
        : !isLoading && <EmptyContent content="No live bet history to show" my={40} justifyContent="center" />}
    </Box>
  )
}

const StyledTable = styled(Table)`
  overflow: hidden;
  position: relative;
  z-index: 1;

  thead {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: ${({ theme }) => theme.colors.background};
  }

  ${TableBody} {
    overflow: hidden;
    z-index: 0;

    ${TableRowHistory} {
      background: transparent !important;
      td {
        background: transparent !important;
      }
      will-change: transform;

      &.active {
        td {
          background: rgba(255, 255, 255, 0.04) !important;
        }
      }
    }
  }

  .animation--start ${TableRowHistory} {
    animation: shuffle 0.5s ease-out;
  }

  @keyframes shuffle {
    0% {
      transform: translateY(-50px);
    }

    to {
      transform: translateY(0);
    }
  }
`

const StyledOverlay = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  height: ${HEIGHT_ROW}px;
  background: ${({ theme }) => theme.colors.background};
`

export default LiveBetTable
