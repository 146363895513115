import { BoxProps } from 'components/Box/types'
import Text from 'components/Text'
import { RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { StyledSelect, WrapperFilter } from 'views/Profile/Transactions/components/Filter/styled'

const LiveBetLimit: React.FC<
  {
    selectedLimit: number
    onSelectLimit: (limit: number) => void
  } & BoxProps
> = ({ selectedLimit, onSelectLimit, ...props }) => {
  const statuses = [10, 20, 30]
  const options = useMemo(() => [...statuses.filter((item) => item !== selectedLimit)], [statuses, selectedLimit])

  return (
    <WrapperFilter minWidth={['80px', '', '', '140px']} {...props}>
      <StyledSelect
        p={['4px 12px', '', '', ' 8px 12px']}
        dropdownContentProps={{
          maxHeight: '160px !important',
          className: 'select-dropdown',
        }}
        options={options}
        onSelect={onSelectLimit}
        OptionItemComponent={FilterOptionItem}
      >
        <FilterOptionItem item={selectedLimit} />
      </StyledSelect>
    </WrapperFilter>
  )
}

const FilterOptionItem = ({ item }: { item: number }) => {
  return (
    <RowMiddle>
      <Text fontSize="1em" color="textTertiary" fontWeight={600}>
        {item}
      </Text>
    </RowMiddle>
  )
}

export default LiveBetLimit
