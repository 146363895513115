import Box from 'components/Box/Box'
import { GridProps } from 'components/Box/types'
import Heading from 'components/Heading'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import Text from 'components/Text'
import { Game } from 'config/types/game'
import { BetDetails } from 'config/types/profile'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import Column from 'layout/Components/Column'
import { RowBetween } from 'layout/Components/Row'
import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import GameService from 'services/GameService'
import { BaseResponse, HunnyRequest, LiveBetTypeEnum, Paging } from 'services/types'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import useComponentOnScreen from 'hooks/useComponentOnScreen'
import LiveBetLimit from './LiveBetLimit'
import LiveBetTable from './LiveBetTable'

interface LiveBetProps {
  game?: Game
}

const LiveBet: React.FC<LiveBetProps & GridProps> = ({ game, ...props }) => {
  const { isLoaded, ref } = useComponentOnScreen({
    root: null,
    rootMargin: '100px',
    threshold: 0,
  })

  const [tab, setTab] = useState(LiveBetTypeEnum.LatestBet)
  const [limit, setLimit] = useState(10)
  const { isMobile } = useMatchBreakpoints()

  const fetchHistory = useCallback((): HunnyRequest<BaseResponse<Paging<BetDetails>>> => {
    return GameService.getLiveBets(game?.code, tab)
  }, [game, tab, limit])

  const handleValidate = useCallback(
    (betTxn: BetDetails) => {
      if (game && game.code !== betTxn.game.code) {
        return false
      }

      if (tab === LiveBetTypeEnum.HighRollers) {
        return Number(betTxn.bet.amountInUsd) >= 100 || Number(betTxn.payout.amountInUsd) >= 100
      }

      return true
    },
    [tab],
  )

  return (
    <Column {...props} ref={ref}>
      <RowBetween>
        <Heading scale="md" display="inline-flex" height="34px" style={{ alignItems: 'center' }}>
          <Trans>Live Bets</Trans>
        </Heading>

        {isMobile ? <LiveBetLimit onSelectLimit={setLimit} selectedLimit={limit} /> : <></>}
      </RowBetween>

      <StyledContent>
        <RowBetween>
          <StyledTabs
            my="20px"
            value={tab}
            onTagChanged={setTab}
            backgroundBlurColor={colors.background}
            indicator={<StyledIndicator />}
            $active={tab}
          >
            <StyledTab value={LiveBetTypeEnum.LatestBet} $active={tab === LiveBetTypeEnum.LatestBet}>
              <Text bold small lineHeight="14px">
                <Trans>Latest Bets</Trans>
              </Text>
            </StyledTab>
            <StyledTabStroke disabled value={null}>
              <StyledStroke />
            </StyledTabStroke>
            <StyledTab value={LiveBetTypeEnum.HighRollers} $active={tab === LiveBetTypeEnum.HighRollers}>
              <Text bold small lineHeight="14px">
                <Trans>High Rollers</Trans>
              </Text>
            </StyledTab>
          </StyledTabs>

          {isMobile ? <></> : <LiveBetLimit onSelectLimit={setLimit} selectedLimit={limit} />}
        </RowBetween>

        <Column>
          {isLoaded && <LiveBetTable fetchFn={fetchHistory} validateBet={handleValidate} displayRowCount={limit} />}
        </Column>
      </StyledContent>
    </Column>
  )
}

const StyledContent = styled(Column)`
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.medium};
`

const StyledTab = styled(Tab)<{ $active: boolean }>`
  padding: 8px 12px;
  min-width: 80px;

  width: 50%;
  line-height: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    min-width: 20px;
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
  }

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
    font-weight: 600;
    font-size: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 12px;
    min-width: 160px;

    ${Text} {
      font-size: 14px;
    }
  }
`

const StyledTabs = styled(Tabs)<{ $active: number }>`
  padding: 8px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: ${({ theme }) => theme.radii.tiny};

  box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.05);

  width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: fit-content;
    width: auto;
  }
`

const StyledTabStroke = styled(Tab)`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 16px;
  cursor: auto;
`

const StyledStroke = styled(Box)`
  width: 1px;
  height: 16px;
  background: #232c3d;
`

const StyledIndicator = styled.div`
  border-radius: ${({ theme }) => theme.radii.tiny};
  background: rgba(255, 255, 255, 0.08);

  height: 100%;
  width: 100%;
`

export default LiveBet
